const columnsCliente = (clientes) => {
  return {
    columns: [
      {
        label: "#",
        field: "clienteId",
        sort: "asc",
      },
      {
        label: "Nome",
        field: "nome",
      },
      {
        label: "CPF",
        field: "cpfFormatado",
      },
      {
        label: "Celular",
        field: "celularFormatado",
      },
      {
        label: "Gênero",
        field: "generoFormatado",
      },
      {
        label: "Cão",
        field: "quantidadeCao",
      },
      {
        label: "Gato",
        field: "quantidadeGato",
      },
      {
        label: "Nascimento",
        field: "dataNascimento",
      },
      {
        label: "Status",
        field: "usuarioStatusFormatado",
      },
      {
        label: "Msg",
        field: "usuarioReceberNotificacaoFormatado",
      },
      {
        label: "Último Acesso",
        field: "usuarioDataUltimoAcesso",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: clientes,
  };
};

const columnsCao = (caes) => {
  return {
    columns: [
      {
        label: "",
        field: "avatar",
        sort: "disabled",
      },
      {
        label: "#",
        field: "animalId",
        sort: "asc",
      },
      {
        label: "Nome",
        field: "nome",
      },
      {
        label: "Raça",
        field: "racaAnimalDescricao",
      },
      {
        label: "Nascimento",
        field: "dataNascimento",
      },
      {
        label: "Data Cadastro",
        field: "dataCadastro",
      },
      {
        label: "Última Alteração",
        field: "dataAlteracao",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: caes,
  };
};

const columnsGato = (gatos) => {
  return {
    columns: [
      {
        label: "",
        field: "avatar",
        sort: "disabled",
      },
      {
        label: "#",
        field: "animalId",
        sort: "asc",
      },

      {
        label: "Nome",
        field: "nome",
      },
      {
        label: "Raça",
        field: "racaAnimalDescricao",
      },
      {
        label: "Nascimento",
        field: "dataNascimento",
      },
      {
        label: "Data Cadastro",
        field: "dataCadastro",
      },
      {
        label: "Última Alteração",
        field: "dataAlteracao",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: gatos,
  };
};

const columnsDispositivo = (dispositivos) => {
  return {
    columns: [
      {
        label: "#",
        field: "dispositivoId",
        sort: "asc",
      },
      {
        label: "Nome",
        field: "nome",
      },
      {
        label: "Modelo",
        field: "modelo",
      },
      {
        label: "SO",
        field: "plataforma",
      },
      {
        label: "Serial",
        field: "pushToken",
      },
      {
        label: "Padrão",
        field: "padrao",
      },
      {
        label: "Data Cadastro",
        field: "dataCadastro",
      },
      {
        label: "Último Acesso",
        field: "dataUltimoAcesso",
      },
    ],
    rows: dispositivos,
  };
};

export { columnsCliente, columnsCao, columnsGato, columnsDispositivo };
